<template>
  <a-row :gutter="[8, 8]" class="py-4 px-2 p-md-4" id="obeya-room-container">
    <a-col :xs="24" :sm="24" :md="8" :lg="8">
      <a-select
        v-model:value="task_id"
        show-search
        ref="obeya_room_task_select"
        placeholder="Select a Operation"
        class="w-100"
        :options="taskOptions"
        @change="onChangeTask"
        :filter-option="filterOption"
        :loading="isFetchingTasks"
        id="obeya-room-select-task"
      >
      </a-select>
    </a-col>
    <a-col :xs="12" :sm="12" :md="4" :lg="4">
      <a-select
        style="width: 100%"
        placeholder="Select Cycle Type"
        :value="selectedVideoType"
        :options="videoTypeOptions"
        @change="val => setSelectedVideoType(val)"
        id="obeya-room-select-video-type-filter"
      >
      </a-select>
    </a-col>
    <a-col :xs="12" :sm="12" :md="4" :lg="4">
      <a-button type="primary" @click="getVideos" id="obeya-room-submit-btn"
        >Submit</a-button
      >
    </a-col>

    <a-col span="24" class="mt-4" id="obeya-room-videos-container">
      <videos-list
        :count="videosCount"
        :list="videoList"
        :sortBy="sortBy"
        :getVideoS3Details="getVideoS3Details"
        @setSortBy="val => (sortBy = val)"
      >
      </videos-list>
    </a-col>

    <a-col span="24" class="d-flex" v-if="videoList.length > 0">
      <a-pagination
        class="mx-auto mt-2"
        :total="videosCount"
        :current="Number(currentPage)"
        :show-size-changer="false"
        :show-total="
          (total, range) => `${range[0]}-${range[1]} of ${total} cycles`
        "
        @change="onPageChange"
        id="obeya-room-videos-pagination"
      >
      </a-pagination>
    </a-col>
  </a-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VideosList from './VideosList.vue';
import spaceMixin from 'src/mixins/handleSpace';
import videoTypes from 'src/config/video-type-config';
import { getSortedTask } from 'src/utils/task';

export default {
  mixins: [spaceMixin],
  components: {
    VideosList
  },
  data() {
    return {
      task_id: undefined,
      searchValue: '',
      currentPage: 1,
      sortBy: 'none'
    };
  },
  created() {
    this.getAllTasks();
    this.populateParams();

    if (!this.selectedTask) {
      this.setVideosList([]);
      this.setVideosCount(0);
    }
  },
  computed: {
    ...mapGetters([
      'selectedTask',
      'allTasks',
      'isFetchingTasks',
      'videoList',
      'videosCount',
      'organization',
      'videoTypes',
      'selectedVideoType'
    ]),

    taskOptions() {
      return getSortedTask(this.allTasks);
    },

    videoTypeOptions() {
      return Object.entries(this.videoTypes).map(([key, value]) => ({
        value: value,
        label: key
      }));
    }
  },
  watch: {
    task_id(value) {
      if (!value) return;
      this.setTaskId(value);
      this.sortBy = 'none';
    },

    currentPage(page) {
      this.getVideos();
    },

    sortBy(value) {
      this.getVideos();
    }
  },
  methods: {
    ...mapActions([
      'getAllTasks',
      'setTaskId',
      'fetchVideos',
      'setVideosList',
      'setVideosCount',
      'setSelectedVideoType'
    ]),

    onPageChange(page) {
      this.currentPage = page;
    },

    onChangeTask() {
      this.currentPage = 1;
      this.setSelectedVideoType(videoTypes['All Videos']);
      this.$refs.obeya_room_task_select.blur();
    },

    setRouteQueryParams(query) {
      this.$router.replace({
        name: this.$route.name,
        query
      });
    },

    getTaskName(taskId) {
      if (!taskId) return;
      return this.taskOptions.find(task => task.value === taskId).label;
    },

    getVideos() {
      if (!this.task_id) return;
      const params = {
        taskId: this.task_id,
        page: this.currentPage,
        type: this.selectedVideoType
      };
      this.fetchVideos({ ...params, sortBy: this.sortBy });
      this.setRouteQueryParams(params);
    },

    populateParams() {
      const { taskId, page, type, sortBy } = this.$route.query;
      this.task_id = taskId && Number(taskId);
      this.currentPage = page ? Number(page) : 1;
      this.setSelectedVideoType(type || videoTypes['All Videos']);
      this.getVideos();
    },

    getVideoS3Details(video) {
      var bucket = '';
      var filePath = '';
      if (!this.task_id) return;
      const { fileName, device_id } = video;
      const taskName = this.getTaskName(this.task_id);
      if (video.isUploadedVideo) {
        bucket = `${this.organization}-training`;
        filePath = `${taskName}/Videos/${fileName}`;
      } else {
        bucket = `${this.organization}-videos`;
        filePath = `${device_id}/${this.task_id}/Processed/${fileName}`;
      }
      return { bucket, filePath };
    }
  }
};
</script>
